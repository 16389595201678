import React from "react";
import footerloog from "../assets/footerlogo.svg";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { CiLinkedin } from "react-icons/ci";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";
import dotalogo from "../assets/dotalogo.svg";
import { Link } from "react-router-dom";
import Button from "./Button";
function Footer() {
  return (
    <div className="flex sm:flex-row desc gap-4 py-4    w-[100%] z-[1000233] sm:gap-0  flex-col justify-between footer px-12">
      <div className=" flex flex-col gap-4 md:w-[20rem] items-start desc justify-center items-center">
        <div className="text-white hidden sm:flex flex-col gap-4 text-center">
          <h1 className=" text-2xl">FOLLOW US</h1>
          <div className="flex gap-8 justify-center items-center text-[#87bfff]">
            <a target="_blank" href="https://www.instagram.com/atmos.bitsh/">
              <FaInstagram size={41} />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/search/top?q=atmos%2C%20bits%20pilani%20hyderabad%20campus"
            >
              <AiOutlineFacebook size={41} />
            </a>

            <a
              target="_blank"
              href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A3764030&keywords=bits%20pilani%2C%20hyderabad%20campus&origin=RICH_QUERY_SUGGESTION&position=6&searchId=099ccea4-4199-4ae8-8080-3dc2b657092e&sid=mJy&spellCorrectionEnabled=false"
            >
              <CiLinkedin size={41} />
            </a>
          </div>
        </div>
      </div>

      <div className="sm:flex flex-col hidden justify-center items-center">
        <h1 className="text-white text-xl text-center">Made by</h1>
        <a target="_blank" href="https://www.instagram.com/dota_bphc/"><img className="w-20" src={dotalogo} alt="" /></a>
      </div>

      {/* <div className="flex flex-col text-xl text-white gap-4 desc w-80 justify-center items-center">
        <h1 className="text-white text-3xl text-center">CONTACT US</h1>
        <h1 className="flex text-left w-full justify-start gap-6 items-center">
          <FaPhoneSquareAlt color="#87bfff" size={35} /> +91 xxxxxxxxxx
        </h1>
        <h1 className="flex text-left w-full underline justify-start gap-6 items-center">
          <IoMdMail color="#87bfff" size={40} />{" "}
          xxxxx@hyderabad.bits-pilani.ac.in
        </h1>
        <h1 className="flex text-left w-full justify-start gap-10 items-center">
          <FaLocationDot color="#87bfff" size={40} /> BITS Pilani Hyderabad
          Campus, Secunderabad, Telangana 500078
        </h1>
      </div> */}

      <div className="flex gap-4 text-white  justify-center items-center ">
        <Link to="/contactus">
          <Button data="Contact Us" />
        </Link>

        <Link to="/register">
          <Button data="Register now" />
        </Link>
      </div>

      <div className="text-white  sm:hidden text-center">
        <h1 className=" text-2xl">FOLLOW US</h1>
        <div className="flex gap-8 justify-center items-center text-[#87bfff]">
          <a target="_blank" href="https://www.instagram.com/atmos.bitsh/">
            <FaInstagram size={41} />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/search/top?q=atmos%2C%20bits%20pilani%20hyderabad%20campus"
          >
            <AiOutlineFacebook size={41} />
          </a>

          <a
            target="_blank"
            href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A3764030&keywords=bits%20pilani%2C%20hyderabad%20campus&origin=RICH_QUERY_SUGGESTION&position=6&searchId=099ccea4-4199-4ae8-8080-3dc2b657092e&sid=mJy&spellCorrectionEnabled=false"
          >
            <CiLinkedin size={41} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
