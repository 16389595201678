import React from "react";
import logo from "../assets/logo.svg";
import greenBox from "../Components/green-box.png";
import Layout from "./Layout";
import Footer from "../Components/Footer";
import Button from "../Components/Button";
import MyNAvbar from "../Components/MyNavbar";
import { Link } from "react-router-dom";
 
const Registeration = () => {
  return (
    // <Layout>
    //   <MyNavbar />
 
    //   <div className="relative min-h-screen text-white overflow-hidden flex flex-col items-center py-10 font-jersey20">
    //     <StarsBackground />
    //     <ShootingStars />
 
    //   <Footer />
    // </Layout>
    <Layout>
      <MyNAvbar/>
      <div className="w-full">
 
        <div className="flex flex-col items-center text-center space-y-6">
            <img src={logo} alt="Event Logo" className="w-64 md:w-72" />
            <p className="text-xl sm:text-2xl lg:text-3xl font-jersey20 max-w-2xl mx-8 text-white">
              The annual Technical fest of BITS Pilani, Hyderabad Campus, that grew into a national-level college tech fest with participation from over 50 colleges across India.
            </p>
        </div>
 
        <div className="my-8 w-full flex justify-center">
           <img
              src={greenBox}
              alt="Stats Section"
              className="w-fit md:w-1/2 object-cover rounded-xl px-4 md:px-0"
            />
        </div>
 
        <div className="flex flex-col items-center space-y-4 mt-16 text-white">
            <h2 className="text-2xl md:text-3xl text-center font-pressStart2p mx-4">Register Now!!</h2>
            <p className="text-xl md:text-2xl text-center max-w-md font-jersey20">
              Click on the links below to dive into Astral Arcade
            </p>
            <div className="flex gap-4 text-white  justify-center items-center font-jersey20">
              <a target="_blank" href="https://swd.bits-hyderabad.ac.in/admin/goodies">
                <Button data="BITSIAN" />
              </a>
 
              <a target="_blank" href="https://bharatversity.com">
                <Button data="NON-BITSIAN" />
              </a>
          </div>
        </div>
 
        <div className="text-white flex flex-col justify-center items-center font-jersey20">
          <h1 className="text-4xl md:text-5xl max-w-4xl px-4 md:px-0 text-center mt-16 mb-8 md:mb-16 md:mt-16">
            Step into a universe of innovation and creativity! Your journey through
            the stars awaits. Choose your adventure below:
          </h1>
 
          <div className="flex justify-center flex-col md:flex-row text-center gap-12 px-12 items-center mb-16">
            {/* Day Pass */}
            <div className="flex flex-col gap-4 md:w-1/2 justify-center items-center">
              <h1 className="text-2xl md:text-3xl underline font-jersey20">Day Executive Pass: The Cosmic Explorer</h1>
              <p className="text-lg md:text-xl">
                Unlock a full day of galactic fun, workshops, and competitions!
                Immerse yourself in a vibrant atmosphere and connect with fellow
                explorers.
              </p>
              <a href="https://unifest.in" target="_blank">
                <Button data="Teleport Me" className="text-xl font-jersey20" />
              </a>
            </div>
 
            {/* Mega Pass */}
            <div className="flex flex-col gap-4 md:w-1/2 justify-center items-center">
              <h1 className="text-2xl md:text-3xl underline font-jersey20">Mega Pass: The Ultimate Galaxy Voyager</h1>
              <p className="text-lg md:text-xl">
                Dive into the complete experience! Enjoy everything the Astral Arcade has to offer—full access to all events, exclusive workshops, and special perks that will elevate your experience to the next dimension.
              </p>
              <a href="https://unifest.in" target="_blank">
                <Button data="Blast Me In" className="text-xl font-jersey20" />
              </a>
            </div>
          </div>
        </div>
      </div>
 
      <Footer/>
    </Layout>
  );
};
 
export default Registeration;
