import React from "react";
import Button from "../../Components/Button";

const Promo = () => {
  // Function to open URLs
  const openLink = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="text-white flex flex-col mt-20 gap-16 pb-16 sm:gap-40 sm:pb-40 justify-center items-center desc">
      <h1 className="sm:text-5xl text-3xl px-4 md:px-0 text-center">
        Step into a universe of innovation and creativity! Your journey through
        the stars awaits. Choose your adventure below:
      </h1>

      <div className="flex justify-center flex-col md:flex-row text-center gap-12 px-12 items-center">
        {/* Day Executive Pass Section */}
        <div className="flex flex-col gap-10 md:w-1/2 justify-center items-center">
          <h1 className="text-4xl underline">Day Executive Pass: The Cosmic Explorer</h1>
          <p className="text-2xl">
            Unlock a full day of galactic fun, workshops, and competitions!
            Immerse yourself in a vibrant atmosphere and connect with fellow
            explorers.
          </p>
          <a href="https://unifest.in" target="_blank">
            <Button data="Teleport Me" className="text-xl font-jersey20" />
          </a>
        </div>

        {/* Mega Pass Section */}
        <div className="flex flex-col gap-10 md:w-1/2 justify-center items-center">
          <h1 className="text-4xl underline">Mega Pass: The Ultimate Galaxy Voyager</h1>
          <p className="text-2xl">
            Dive into the complete experience! Enjoy everything the Astral Arcade has to offer—full access to all events, exclusive workshops, and special perks that will elevate your experience to the next dimension.
          </p>
          <a href="https://unifest.in" target="_blank">
            <Button data="Blast Me In" className="text-xl font-jersey20" />
          </a>        
          </div>
      </div>
    </div>
  );
};

export default Promo;
