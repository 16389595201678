import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Layout from "./pages/Layout";
import Events from "./pages/Events";
import Sponsors from "./pages/Sponsors";
import Error from "./pages/Error";
import ContactUs from './pages/ContactUs';
import Gallery from './pages/Gallery';
import Registeration from './pages/Registeration';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route index path='/' element={<Home />} />
      <Route path='/home' element={<Home />} />
      <Route path='/gallery' element={<Gallery />} />
      <Route path='/events' element={<Events />} />
      <Route path='/sponsors' element={<Sponsors />} />
      <Route path='*' element={<Error />} />
      <Route path='/contactus' element={<ContactUs/>} />
      <Route path='/register' element={<Registeration/>} />
    </Routes>
  </BrowserRouter>
);

reportWebVitals();
