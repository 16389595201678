import React from "react";
import about1 from "../../assets/about1.jpeg";
import about2 from "../../assets/about2.jpeg";
import ImageSlider from "./Slider";

function About() {
    return (
        <div className="flex flex-col gap-8 justify-center items-center py-10"> {/* Added padding for spacing */}
            <h1 className="heading-about text-[#65B5EB] text-3xl sm:text-5xl lg:text-6xl">
                About us
            </h1>
            <h1 className="text-white w-[70%] text-xl sm:text-3xl lg:text-4xl desc text-center">
                Gear up for Atmos 24, BITS Pilani Hyderabad's tech fest like no other, set to blow your mind from November 8 to 10!
                Dive into the heart-pounding action of Robo Wars, soar with the Drone Racing League, and challenge your wits with Cruxifier.
                But the thrills don't stop there-electrifying performances will light up the nights, blending tech and entertainment into one unforgettable event.
                Be there to witness the future of innovation, live!
            </h1>

            <div className="hidden w-full sm:flex justify-evenly pt-12 items-center">
                <img className="lg:w-96 rounded-xl w-72" src={about1} alt="About Image 1" />
                <img className="lg:w-96 rounded-xl w-72" src={about2} alt="About Image 2" />
            </div>

            <div className="sm:hidden">
                <ImageSlider />
            </div>
        </div>
    );
}

export default About;
