import React, { useEffect, useState } from "react";
import rocketImg from "../../assets/Rocket101.png.jpeg"; // Correct path for rocket image
import atmosLogo from "../../assets/image.png"; // Correct path for ATMOS logo

const RocketCounter = () => {
  const calculateTimeLeft = () => {
    const eventDate = new Date("2024-11-08T00:00:00").getTime();
    const now = new Date().getTime();
    const difference = eventDate - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [rocketLoaded, setRocketLoaded] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Preload rocket image to avoid loading delay
  useEffect(() => {
    const preloadRocketImage = new Image();
    preloadRocketImage.src = rocketImg;
    preloadRocketImage.onload = () => setRocketLoaded(true);
  }, []);

  return (
    <div className="relative w-full h-[700px] flex flex-col justify-center items-center overflow-hidden">
      {/* Atmos Logo */}
      <div className="relative w-[250px] h-[250px] md:w-[300px] md:h-[300px] lg:w-[350px] lg:h-[350px] flex justify-center items-center mb-8">
        <img
          src={atmosLogo}
          alt="ATMOS Logo"
          className="w-full h-full object-contain"
        />
      </div>

      {/* Rocket and Timer container */}
      <div className="relative w-full h-[200px] flex items-center justify-center">
        {/* Rocket Animation */}
        {rocketLoaded && (
          <div className="relative w-full max-w-[900px] h-[300px] md:h-[350px] lg:h-[400px] animate-slide-left">
            <img
              src={rocketImg}
              alt="Rocket"
              className="w-full h-full object-contain"
            />

            {/* Timers inside the rocket windows */}
            <div className="absolute top-0 left-0 w-full h-full flex justify-between items-center px-4 font-jersey20">
              {/* Day Timer */}
              <div className="absolute top-[50%] left-[35.5%] transform -translate-x-1/2 -translate-y-1/2 w-[80px] h-[80px] md:w-[90px] md:h-[90px] lg:w-[100px] lg:h-[100px] flex flex-col justify-center items-center">
                <span className="text-white text-xl md:text-2xl lg:text-3xl font-bold -mb-2">
                  {timeLeft.days || "0"}
                </span>
                <span className="text-white text-base font-bold">Days</span>
              </div>

              {/* Hour Timer */}
              <div className="absolute top-[50%] left-[51.5%] transform -translate-x-1/2 -translate-y-1/2 w-[80px] h-[80px] md:w-[90px] md:h-[90px] lg:w-[100px] lg:h-[100px] flex flex-col justify-center items-center">
                <span className="text-white text-xl md:text-2xl lg:text-3xl font-bold -mb-2">
                  {timeLeft.hours || "0"}
                </span>
                <span className="text-white text-sm font-bold">Hours</span>
              </div>

              {/* Minute Timer */}
              <div className="absolute top-[50%] left-[67.6%] transform -translate-x-1/2 -translate-y-1/2 w-[80px] h-[80px] md:w-[90px] md:h-[90px] lg:w-[100px] lg:h-[100px] flex flex-col justify-center items-center">
                <span className="text-white text-xl md:text-2xl lg:text-3xl font-bold -mb-2">
                  {timeLeft.minutes || "0"}
                </span>
                <span className="text-white text-sm font-bold">Minutes</span>
              </div>

              {/* Second Timer */}
              <div className="absolute top-[50%] left-[83.5%] transform -translate-x-1/2 -translate-y-1/2 w-[80px] h-[80px] md:w-[90px] md:h-[90px] lg:w-[100px] lg:h-[100px] flex flex-col justify-center items-center">
                <span className="text-white text-xl md:text-2xl lg:text-3xl font-bold -mb-2">
                  {timeLeft.seconds || "0"}
                </span>
                <span className="text-white text-sm font-bold">Seconds</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RocketCounter;
