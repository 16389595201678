import React from "react";

const VideoPlayer = () => {
    const videoLinks = [
        "https://www.youtube.com/embed/K0cAcOpK3ug",
        "https://www.youtube.com/embed/WVNhwu6MiyU",
    ];

    const videoTexts = [
        "ATMoS '23, held from November 3rd to 5th at BITS Pilani, Hyderabad, was a vibrant celebration of innovation, with workshops in Verilog, Machine Learning, IoT, and Astrophotography, and guests like Shraddha Kapra and Aman Dhattarwal. Exciting activities like Drone Racing and Robo Wars kept the energy high. Let’s relive these unforgettable moments as we gear up for ATMOS '24!",
        "ATMOS'22 was a thrilling fest packed with highlights like Robowars, Cruxipher Challenge, and Operation Zodiac. The event also featured an inspiring talk by Adivi Sesh and an electrifying performance by Arjun Kanungo. As we gear up for ATMOS'24, take a trip down memory lane with the official aftermovie and relive the excitement!",
    ];

    return (
        <div className="p-5 pt-20 max-w-screen-xl mx-auto font-jersey20"> {/* Center content and limit width */}
            <div className="grid grid-cols-1 gap-10">
                {videoLinks.map((link, index) => (
                    <div
                        key={index}
                        className={`flex flex-col md:flex-row ${index === 1 ? "md:flex-row-reverse" : ""} items-center justify-center`} // Reverse layout for Video 2
                    >
                        <div className="relative w-full max-w-[450px] h-[250px] flex-shrink-0 overflow-hidden rounded-lg mb-4 md:mb-0">
                            <iframe
                                className="absolute top-0 left-0 w-full h-full rounded-lg"
                                src={link}
                                title={`YouTube Video ${index}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                        <div className="flex-1 px-4 md:pl-8 md:pr-4">
                            <h3 className="text-3xl md:text-4xl font-bold text-blue-500 text-center md:text-left mt-2">
                                {`ATMOS VIDEO #${index + 1}`}
                            </h3>
                            <p className={`text-xl md:text-2xly text-white text-center md:text-left ${index === 0 ? "line-clamp-4" : "line-clamp-5"} mt-2`}>
                                {videoTexts[index]}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VideoPlayer;
