import "./globals.css";
import Layout from "./pages/Layout";
import Main from "./pages/Main/Main";
import ContactUs from "./pages/ContactUs";
function App() {
  return (
    <>
      <Main />
    </>
  );
}

export default App;


