import React, { useState } from "react";
import Layout from "./Layout";
import MyNAvbar from "../Components/MyNavbar.js";
import Footer from "../Components/Footer";

const Events = () => {
  const [selectedSection, setSelectedSection] = useState("workshops");

  const workshops = {
    1: {
      name: "DOTA",
      image: "https://i.postimg.cc/HxhdGr1c/IMG-20241024-WA0052-1.jpg",
    },
    2: {
      name: "Alchemy 1",
      image: "https://i.postimg.cc/hv0FDvH1/350x-350-s.png",
    },
    3: {
      name: "Alchemy 2",
      image: "https://i.postimg.cc/MTMKp7Db/350x350.png",
    },
    4: {
      name: "ACM",
      image: "https://i.postimg.cc/d09VfQ0r/ace1.png",
    },
    5: {
      name: "Ad Astra 1",
      image: "https://i.postimg.cc/50x69Yrs/adastra1.png",
    },
    6: {
      name: "Ad Astra 2",
      image: "https://i.postimg.cc/hGKSVF0j/adastra2.png",
    },
    7: {
      name: "Ad Astra 3",
      image: "https://i.postimg.cc/sDpxWJRT/adastra4.png",
    },
    8: {
      name: "IEEE 1",
      image: "https://i.postimg.cc/rsTX2xYp/amp1.png",
    },
    9: {
      name: "IEEE 2",
      image: "https://i.postimg.cc/LsSsvgmm/ieee2.jpg",
    },
    10: {
      name: "IEEE 3",
      image: "https://i.postimg.cc/sxWXwYGD/ieee4.jpg",
    },
    11: {
      name: "IEEE 4",
      image: "https://i.postimg.cc/Ls4J2gwN/Quantum-Verse-1080x1080-ieee.png",
    },
    12: {
      name: "CRUX",
      image: "https://i.postimg.cc/mr0WV0YC/cruxdsa1.jpg",
    },
    13: {
      name: "Axiom",
      image: "https://i.postimg.cc/Lss8rKgW/crypto-ws350x350.png",
    },
    14: {
      name: "CSA",
      image: "https://i.postimg.cc/fLJk0nwd/csa1.png",
    },
    15: {
      name: "DoPY",
      image: "https://i.postimg.cc/tT27sRQp/dopy1.png",
    },
    16: {
      name: "Phoenix",
      image: "https://i.postimg.cc/s2K3cnnJ/phoenix1.png",
    },
    17: {
      name: "Spectrum",
      image: "https://i.postimg.cc/kG2MDxvv/spectrumw.png",
    },
    18: {
      name: "Traders",
      image: "https://i.postimg.cc/3Rsr4kfM/traders1.png",
    },
    19: {
      name: "Wall Street",
      image: "https://i.postimg.cc/K8Scv3kt/wallstreet2.jpg",
    },
    20: {
      name: "Panacea",
      image: "https://i.postimg.cc/Y9Z06n9s/SOAP-350-X350.jpg",
    },
  };

  const competitions = {
    1: {
      name: "Ad Astra",
      image: "https://i.postimg.cc/nVDjpD0G/adastra3.png"
    },
    2: {
      name: "Crux",
      image: "https://i.postimg.cc/hP8X7qFJ/crux2.png"
    },
    3: {
      name: "Crux",
      image: "https://i.postimg.cc/4NGdGFKX/crux3c.jpg"
    },
    4: {
      name: "CSA",
      image: "https://i.postimg.cc/52N2YYqG/csa3.png"
    },
    5: {
      name: "Panacea",
      image: "https://i.postimg.cc/m2WcDgnf/CSI-350-X350.jpg"
    },
    6: {
      name: "Axiom",
      image: "https://i.postimg.cc/dVqL2Sv5/cubing-axiom1.png"
    },
    7: {
      name: "I-Cell",
      image: "https://i.postimg.cc/L5KsnxNB/i-cell-1.png"
    },
    8: {
      name: "Panacea",
      image: "https://i.postimg.cc/TP11SLKm/PPP-350-X350.jpg"
    },
    9: {
      name: "Phoenix",
      image: "https://i.postimg.cc/63G3WkLp/ROBO-WARS-POSTER-Recovered.png"
    },
    9: {
      name: "CSA",
      image: "https://i.postimg.cc/bJbycQpG/SPACE-ESCAPE-insta-4.png"
    },
    10: {
      name: "Spectrum",
      image: "https://i.postimg.cc/qqTkkzv1/spectrum1.png"
    },
    11: {
      name: "Wall Street",
      image: "https://i.postimg.cc/6Q86ckPf/square1.jpg"
    },
    12: {
      name: "Wall Street",
      image: "https://i.postimg.cc/Qd4xp8fM/wallstreet1.jpg"
    },
    13: {
      name: "Traders",
      image: "https://i.postimg.cc/Gtxb9dBd/wallstreet3.jpg"
    },
  };
  

  const renderImages = () => {
    return (
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 lg:gap-12">
        {Array.from({
          length:
            selectedSection === "workshops"
              ? Object.keys(workshops).length
              : Object.keys(competitions).length,
        }).map((_, index) => (
          <div>
            <img
              key={index}
              src={
                selectedSection === "workshops"
                  ? workshops[index + 1].image
                  : competitions[index + 1].image
              }
              alt={`${selectedSection} Image ${index + 1}`}
              className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2 
              shadow-md shadow-slate-400 hover:cursor-pointer hover:scale-105 duration-150 ease-linear" // Fixed size 300x300
            />
            <h1 className="text-3xl md:text-4xl mt-2 text-gray-200 text-center font-jersey20 uppercase flex justify-center">
              {selectedSection === "workshops"
                ? workshops[index + 1].name
                : competitions[index + 1].name}
            </h1>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Layout>
      <MyNAvbar />
      <div className="relative min-h-screen flex flex-col w-full px-4 overflow-y-auto">
        <div className="relative mt-4 sm:mt-10 max-w-full">
          <h1 className="text-5xl md:text-7xl text-white text-center font-jersey20 uppercase">
            ATMOS 2024 EVENTS
          </h1>
        </div>

        <div className="flex flex-col items-center mt-6 sm:mt-8 flex-grow">
          <div className="flex flex-wrap justify-center space-x-8">
            <button
              onClick={() => setSelectedSection("workshops")}
              className={`text-3xl md:text-4xl text-white text-center font-jersey20 uppercase ${
                selectedSection === "workshops"
                  ? "underline underline-offset-4 decoration-[#64b5eb]"
                  : ""
              }`}
            >
              Workshops
            </button>
            <button
              onClick={() => setSelectedSection("competitions")}
              className={`text-3xl md:text-4xl text-white text-center font-jersey20 uppercase ${
                selectedSection === "competitions"
                  ? "underline underline-offset-4 decoration-[#64b5eb]"
                  : ""
              }`}
            >
              Competitions
            </button>
          </div>
        </div>
        <div className="w-full my-8 items-center justify-center flex">
          {renderImages()}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default Events;
