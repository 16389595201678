import React from "react";
import {
  MdOutlineKeyboardArrowRight,
  MdOutlineKeyboardArrowLeft,
} from "react-icons/md";
import { Carousel } from "flowbite-react";
import Layout from "./Layout";
import MyNAvbar from "../Components/MyNavbar";
import Footer from "../Components/Footer";
import IIM from "../Components/2IIM.png";
import Corzio from "../Components/Corzio_logo.png";
import Print from "../Components/ThePrint logo.png";
import dailyHunt from "../Components/DailyHunt.png";
import Edtimes from "../Components/EDTimes.png";
import funda from "../Components/Fundaspring.png";
import campustimes from "../Components/Campus Times Pune.png";
import pagalguy from "../Components/Pagalguy.png";
import BV from "../Components/BV.png";
import blogadda from "../Components/BA_Logo_Blue_On_White.png";
import global from "../Components/1. TGH Logo White.png";
import ivy from "../Components/ivy-overseas-logo.png";
import dopamine from "../Components/Dopamine Store.png";
import allen from "../Components/allen.jpg";
import pratham from "../Components/Pratham Beverages.jpg";
import etv from "../Components/ETV_TG BIG.png";
import krafton from "../Components/KRAFTON_IN_ESPORTS_For_Dark_BG.png";



const Sponsors = () => {
  return (
    <Layout>
      <MyNAvbar />
      <section className="w-full flex items-center justify-center px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-20">
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
            Official Mobile Gaming partner
            </h1>
            <a
              className=""
              target="_blank"
              href=""
            >
              <img
                src={krafton}
                alt="Exclusive Ticketing Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>


          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Exclusive Ticketing Partner{" "}
            </h1>
            <a
              className="bg-white"
              target="_blank"
              href="https://bharatversity.com/dashboard/event"
            >
              <img
                src={BV}
                alt="Exclusive Ticketing Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>

          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Study Abroad Partner{" "}
            </h1>
            <a
              className="bg-white"
              target="_blank"
              href="https://www.ivyoverseas.com/"
            >
              <img
                src={ivy}
                alt="Study Abroad Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>

          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Anime Aesthetics Partner
            </h1>
            <a className="bg-white" target="_blank" href="">
              <img
                src={dopamine}
                alt="Anime Aesthetics Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>

          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Title Sponser innoWAVE'24
            </h1>
            <a
              className="bg-white"
              target="_blank"
              href="https://www.allen.ac.in/"
            >
              <img
                src={allen}
                alt="Title Sponser innoWAVE'24"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>

          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Offical Water Partner
            </h1>
            <a className="bg-white" target="_blank" href="">
              <img
                src={pratham}
                alt="Offical Water Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>

          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Broadcast and Digital MediaPartner
            </h1>
            <a className="bg-white" target="_blank" href="">
              <img
                src={etv}
                alt="Broadcast and Digital MediaPartner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Media Partners
            </h1>
            <Carousel
              indicators={false}
              className="w-64 h-64 md:w-72 md:h-72"
              leftControl={
                <MdOutlineKeyboardArrowLeft className="bg-stone-900 w-6 h-6 rounded-full text-white" />
              }
              rightControl={
                <MdOutlineKeyboardArrowRight className="bg-stone-900 w-6 h-6 rounded-full text-white" />
              }
            >
              <a target="_blank" href="https://theglobalhues.com/">
                <img
                  src={global}
                  alt="..."
                  className="w-64 h-64 md:w-72 md:h-72"
                />
              </a>
              <a target="_blank" href="https://www.pagalguy.com/">
                <img
                  src={pagalguy}
                  alt="..."
                  className="w-64 h-64 md:w-72 md:h-72"
                />
              </a>
            </Carousel>
          </div>

          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Online Media Partner
            </h1>
            <Carousel
              indicators={false}
              className="w-64 h-64 md:w-72 md:h-72"
              leftControl={
                <MdOutlineKeyboardArrowLeft className="bg-stone-900 w-6 h-6 rounded-full text-white" />
              }
              rightControl={
                <MdOutlineKeyboardArrowRight className="bg-stone-900 w-6 h-6 rounded-full text-white" />
              }
            >
              <a target="_blank" href="https://www.campustimespune.com/">
                <img
                  src={campustimes}
                  alt="..."
                  className="w-64 h-64 md:w-72 md:h-72"
                />
              </a>
              <a target="_blank" href="https://fundaspring.com/">
                <img
                  src={funda}
                  alt="..."
                  className="w-64 h-64 md:w-72 md:h-72"
                />
              </a>
            </Carousel>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Official Media Partners
            </h1>
            <a target="_blank" href="https://theprint.in/">
              <img
                src={Print}
                alt="Media Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Offical Digital App Partner
            </h1>
            <a
              className="flex justify-center items-center"
              target="_blank"
              href="https://m.dailyhunt.in"
            >
              <img
                src={dailyHunt}
                alt="Offical Digital App Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Educational Partner
            </h1>
            <a target="_blank" href="https://www.2iim.com/">
              <img
                src={IIM}
                alt="Learning Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Skill Up Partner
            </h1>
            <a target="_blank" href="https://corizo.in/">
              <img
                src={Corzio}
                alt="Skill Up Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>

          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Blog Partner
            </h1>
            <a className="bg-white" target="_blank" href="https://edtimes.in/">
              <img
                src={Edtimes}
                alt="Blog Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>

          <div className="flex flex-col justify-center items-center">
            <h1 className="text-white font-jersey20 text-2xl md:text-4xl text-center mb-4">
              Blogger Outreach Partner
            </h1>
            <a
              className="bg-white"
              target="_blank"
              href="https://www.blogadda.com/"
            >
              <img
                src={blogadda}
                alt="Blogger Outreach Partner"
                className="w-64 h-64 md:w-72 md:h-72"
              />
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  );
};

export default Sponsors;
