import React from "react";
import About from "./About";
import Promo from "./Promo";
import Footer from "../../Components/Footer";
import MyNAvbar from "../../Components/MyNavbar";
import RocketCountdown from "./RockCountdown"; // Correct import
import VideoPlayer from "./VideoPlayer";
function Main() {
  return (
    <>
      <div>
        <MyNAvbar display="" justify="" />
        <RocketCountdown />
              <About />
              <VideoPlayer />
              <Promo />
        <Footer />
      </div>
    </>
  );
}

export default Main;
