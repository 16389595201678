import React, { useState } from "react";
import about1 from "../../assets/about1.jpeg";
import about2 from "../../assets/about2.jpeg";

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [{ about1 }, { about2 }];

  const prevSlide = () => {
    setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
  };

  const nextSlide = () => {
    setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
  };

  return (
    <div className="relative w-72 max-w-lg mx-auto">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          <img className="w-72" src={about1} alt="" />
          <img className="w-72"  src={about2} alt="" />
        </div>
      </div>
      {/* Left Button */}
      <button
        onClick={prevSlide}
        className="absolute top-1/2 left-0 transform -translate-y-1/2  text-white p-2 rounded-full"
      >
        ❮
      </button>
      {/* Right Button */}
      <button
        onClick={nextSlide}
        className="absolute top-1/2 right-0 transform -translate-y-1/2  text-white p-2 rounded-full"
      >
        ❯
      </button>
    </div>
  );
};

export default ImageSlider;
