import React from "react";
import Layout from "./Layout";
import MyNavbar from "../Components/MyNavbar.js";
import Footer from "../Components/Footer";

const ContactUs = () => {
  const subheadings = [
    {
      title: 'Conveners',
      images: [
        { src: 'https://i.postimg.cc/sXFSzGWX/Screenshot-2024-10-28-at-1-37-39-AM.png', name: 'Preetam Reddy Basarahalli - President' },
        { src: 'https://i.postimg.cc/ydM2Hj1G/Screenshot-2024-10-28-at-1-45-40-AM.png', name: 'Vishwaak Reddy - General Secretary' },
        { src: 'https://i.postimg.cc/9MK4C1Z2/Whats-App-Image-2024-10-27-at-13-11-16.jpg', name: 'Divyansh Gangwar - Technical Secretary' }
      ]
    },
    {
      title: 'Developers',
      images: [
        { src: 'https://i.postimg.cc/WzCq9RSK/WEB-LEAD.jpg', name: 'Priyansh Agarwal - Web Dev Lead' },
        { src: 'https://i.postimg.cc/k4vg622f/Screenshot-2024-10-28-at-1-39-55-AM.png', name: 'Rishabh Nanda - UI/UX Lead' }, /*photo needs to be updated*/
        { src: 'https://i.postimg.cc/sDVsQHc9/Whats-App-Image-2024-10-28-at-01-24-10.jpg', name: 'Prakhar Dixit - Design Lead' }
      ]
    },
    {
      title: 'Junior Developers',
      images: [
        { src: 'https://i.postimg.cc/Y2btDkBK/Whats-App-Image-2024-10-27-at-21-51-22.jpg', name: 'Shivansh Shekher Ojha' },
        { src: 'https://i.postimg.cc/Dzt6MSF7/akshay.jpg', name: 'Sai Surya Subhakshay Vemparala' }, 
        { src: 'https://i.postimg.cc/dVPvLjbn/Untitled.png', name: 'Atiksh Gupta' },
        { src: 'https://i.postimg.cc/wBM7SnZM/Whats-App-Image-2024-10-27-at-18-47-54.jpg', name: 'Harit Khera' },
        { src: 'https://i.postimg.cc/bw92kxc0/Whats-App-Image-2024-10-27-at-14-34-33.jpg', name: 'Shubham Sagar' },
        { src: 'https://i.postimg.cc/d0rsHbxZ/Whats-App-Image-2024-10-28-at-12-37-33.jpg', name: 'Yashwant Rajesh' },
        { src: 'https://i.postimg.cc/pTp9w39k/Whats-App-Image-2024-10-27-at-18-21-17.jpg', name: 'Kartik Khandelwal' }
      ]
    },
    {
      title: 'Outreach Team',
      images: [
        { src: 'https://i.postimg.cc/xdsJF9dc/Whats-App-Image-2024-10-27-at-13-10-59.jpg', name: 'Utkarsh Singhal - Sponsorship Head', phone: '+91 83768 23445' },
        { src: 'https://i.postimg.cc/wTTWJgb7/Whats-App-Image-2024-10-27-at-12-32-40.jpg', name: 'Yaswanth Ambati - Publicity Head', phone: '+91 93923 44036'}
      ]
    }
  ];

  return (
    <Layout>
      <MyNavbar />
      <div className="flex-grow flex flex-col items-center p-8">
        <h1 className="text-4xl font-jersey20 text-center text-white mb-10">Contact Us</h1>

        {subheadings.map((section, index) => (
          <div key={index} className="mb-10">
            <h2 className="text-2xl font-jersey20 text-center text-white mb-6">{section.title}</h2>

            {/* Layout for each section based on the image */}
            {section.title === 'Junior Developers' ? (
              <>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-16 justify-items-center mb-8">
                  {section.images.slice(0, 2).map((img, idx) => (
                    <div key={idx} className="flex flex-col items-center">
                      <img
                        src={img.src}
                        alt={img.name}
                        className="w-80 h-48 object-cover rounded-lg mb-4"
                      />
                      <p className="text-lg font-jersey20 text-white">{img.name}</p>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-20 justify-items-center mb-8">
                  {section.images.slice(2, 5).map((img, idx) => (
                    <div key={idx} className="flex flex-col items-center">
                      <img
                        src={img.src}
                        alt={img.name}
                        className="w-80 h-48 object-cover rounded-lg mb-4"
                      />
                      <p className="text-lg font-jersey20 text-white">{img.name}</p>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-20 justify-items-center mb-8">
                  {section.images.slice(5, 7).map((img, idx) => (
                    <div key={idx} className="flex flex-col items-center">
                      <img
                        src={img.src}
                        alt={img.name}
                        className="w-80 h-48 object-cover rounded-lg mb-4"
                      />
                      <p className="text-lg font-jersey20 text-white">{img.name}</p>
                    </div>
                  ))}
                </div>
              </>
            ) : section.title === 'Outreach Team' ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-20 justify-items-center">
                {section.images.map((img, idx) => (
                  <div key={idx} className="flex flex-col items-center">
                    <img
                      src={img.src}
                      alt={img.name}
                      className="w-80 h-48 object-cover rounded-lg mb-4"
                    />
                    <p className="text-lg font-jersey20 text-white">{img.name}</p>
                    <p className="text-lg font-jersey20 text-white">{img.phone}</p>
                  </div>
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-3 gap-20 justify-items-center">
                {section.images.map((img, idx) => (
                  <div key={idx} className="flex flex-col items-center">
                    <img
                      src={img.src}
                      alt={img.name}
                      className="w-80 h-48 object-cover rounded-lg mb-4"
                    />
                    <p className="text-lg font-jersey20 text-white">{img.name}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </Layout>
  );
};

export default ContactUs;
