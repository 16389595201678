import React from 'react';
import { Link } from "react-router-dom";
import { ShootingStars } from "../Components/Shooting-stars.jsx";
import { StarsBackground } from "../Components/Stars-background.jsx";
const Layout = ({ children }) => {
  return (
    <div className="relative min-h-screen overflow-hidden">
      <div className="absolute top-0 left-0 -z-50 h-full w-full bg-zinc-900">
        <nav>
          {/* Push navbar code here */}
        </nav>
      </div>
      <main className="relative z-10">
        {children}
      </main>
      <ShootingStars className={"-z-50"} starWidth={20} starHeight={2} minDelay={750 / 2} maxDelay={1500 / 2} />
      <StarsBackground className={"-z-50"} starDensity={0.00015 * 4} twinkleProbability={0.9} allStarsTwinkle />
    </div>
  );
};

export default Layout;