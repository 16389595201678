import React, { useState } from "react";
import Layout from "./Layout.js";
import { ShootingStars } from "../Components/Shooting-stars.jsx";
import { StarsBackground } from "../Components/Stars-background.jsx";
import MyNAvbar from "../Components/MyNavbar.js";
import Footer from "../Components/Footer.js";

const Gallery = () => {
  return (
    <Layout>
      <div className="w-full">
        <MyNAvbar/>
        {/* <section className="flex flex-col justify-center items-center mx-4 z-50 h-[80vh] md:h-[75vh]">
          <h1 className="text-4xl md:text-5xl text-white my-4 md:my-8 text-center font-jersey20 uppercase ">
              Current Pro Shows
          </h1>
          <div className="grid grid-rows-3 grid-cols-2 md:grid-rows-2 md:grid-cols-3 gap-x-3 gap-y-3 md:gap-y-12 md:gap-x-12 z-50">
            <div>
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl"
                  src="https://via.placeholder.com/350x350"
                  alt="proshow1"
                />
            </div>
            <div>
              <img
                className="w-52 h-52 md:w-72 md:h-72 rounded-xl"
                src="https://via.placeholder.com/350x350"
                alt="proshow2"
              />
            </div>
            <div>
              <img
                className="w-52 h-52 md:w-72 md:h-72 rounded-xl"
                src="https://via.placeholder.com/350x350"
                alt="proshow3"
              />
            </div>
            <div>
              <img
                className="w-52 h-52 md:w-72 md:h-72 rounded-xl"
                src="https://via.placeholder.com/350x350"
                alt="proshow4"
              />
            </div>
            <div>
              <img
                className="w-52 h-52 md:w-72 md:h-72 rounded-xl"
                src="https://via.placeholder.com/350x350"
                alt="proshow4"
              />
            </div>
            <div>
              <img
                className="w-52 h-52 md:w-72 md:h-72 rounded-xl"
                src="https://via.placeholder.com/350x350"
                alt="proshow4"
              />
            </div>
        </div>
        </section> */}
        <div className="w-full mb-16">
          <section className="flex flex-col justify-center items-center mx-4">
            <div>
              <h1 className="text-4xl md:text-5xl text-white my-4 md:my-8 text-center font-jersey20 uppercase">
                ATMOS IMAGE GALLERY
              </h1>
              <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 lg:gap-12">
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/zBt1vggj/17-G7sxzk-Cui-KEUP-m-FL8-You-Ex-Cr-DQbkr-Fw3455-h1778-iv1.png"
                  alt="proshow1"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/cJ7xxCh9/17-U21-F7-FCaifrh-Ns-THFsb-UKdp-R8-Zt-EEi-Ww3455-h1778-iv1.png"
                  alt="proshow2"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/hjpKmYHV/Black-and-White-Monochrome-Tech-Logo.jpg"
                  alt="proshow3"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/fyyDfKNp/Black-and-White-Monochrome-Tech-Logo.png"
                  alt="proshow4"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/T1KvD3jX/Doc-Scanner-17-Oct-2024-3-43-pm14.jpg"
                  alt="proshow5"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/qRMTpLzq/Doc-Scanner-17-Oct-2024-3-43-pm15.jpg"
                  alt="proshow6"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/mZnsG7RZ/Doc-Scanner-17-Oct-2024-3-43-pm4.jpg"
                  alt="proshow7"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/QCvGkZSK/Doc-Scanner-17-Oct-2024-3-43-pm8.jpg"
                  alt="proshow8"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/fbssrZsx/Doc-Scanner-17-Oct-2024-4-08-pm.jpg"
                  alt="proshow9"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/RZK9dhkS/Doc-Scanner-17-Oct-2024-4-18-pm.jpg"
                  alt="proshow10"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/YqJMQYyD/Doc-Scanner-17-Oct-2024-4-18-pm1.jpg"
                  alt="proshow11"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/8PFTFtwr/Doc-Scanner-17-Oct-2024-4-18-pm4.jpg"
                  alt="proshow12"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/1R795L0Q/Doc-Scanner-17-Oct-2024-4-18-pm5.jpg"
                  alt="proshow13"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/W322hVf2/Doc-Scanner-17-Oct-2024-4-30-pm14.jpg"
                  alt="proshow14"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/BvfZfHTP/Doc-Scanner-17-Oct-2024-4-30-pm16.jpg"
                  alt="proshow15"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/d3fvHyQ1/Doc-Scanner-17-Oct-2024-4-30-pm7.jpg"
                  alt="proshow16"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/gk5k4frD/remix-db6d7e5b-cfd0-483d-bdfe-93e1e6cc3aa6.png"
                  alt="proshow17"
                />
                <img
                  className="w-52 h-52 md:w-72 md:h-72 rounded-xl object-cover border-white border-2"
                  src="https://i.postimg.cc/q7021t54/Doc-Scanner-17-Oct-2024-4-08-pm9.jpg"
                  alt="proshow18"
                />
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer/>

    </Layout>
  );
};

export default Gallery;