import React from 'react'
import Layout from './Layout'
import atmos_logo from './atmos_logo.png'

const Error = () => {
  return (
    <Layout>
        <section className='px-8'>
          <div className="font-pressStart2p text-white w-fullh-screen flex flex-col justify-center items-center h-screen text-center">
            <img className='h-72 md:h-[28rem] mb-4 md:mb-8 select-none' src={atmos_logo}/>
            <h1 className='text-3xl md:text-5xl uppercase mb-4 tracking-[0.5rem]'>404 Error</h1>
            <h2 className='text-xl md:text-3xl mb-4 uppercase'>Page not found</h2>
            <h3 className='text-base md:text-xl mb-6'>Lost in Space? Looks like you’ve hit an asteroid!</h3>
            <a className="bg-gradient-to-b from-[#2340CA] to-[#69BDEC] py-2 px-4 rounded-md border-white border-2 cursor-pointer text-sm md:text-base hover:bg-white hover:text-stone-900 duration-300 ease-linear" href="/">Back to Home!</a>
          </div>
        </section>
    </Layout>
  )
}

export default Error