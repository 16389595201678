import React from "react";
import logo from "../assets/logo.svg";
import { IoMenu } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { IoCloseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { LuGlobe2, LuCalendar, LuImage, LuHeartHandshake } from "react-icons/lu";

function MyNAvbar({ display = "block", justify = "space-between" }) {
  const openNav = () => {
    const upNav = document.querySelector("#upNav");
    if (upNav) {
      upNav.classList.remove("translate-y-[-100%]");
    }
  };

  const closeNav = () => {
    const upNav = document.querySelector("#upNav");
    if (upNav) {
      upNav.classList.add("translate-y-[-100%]");
    }
  };

  return (
    <div
      id="navbar"
      style={{ justifyContent: justify }}
      className="bg-none flex lg:px-16 px-4 justify-between z-10000 w-full items-center py-8 text-white"
    >
      <Link to="/">
        <img
          className="w-32 lg:w-60 n"
          src={logo}
          alt=""
          style={{ display: display }}
        />
      </Link>

      <div className="lg:gap-20 gap-10 desc lg:text-4xl md:text-3xl sm:text-2xl hidden sm:flex text-[#64B5EB]">
        <Link className="hover:underline" to="/">
          Home
        </Link>
        <Link className="hover:underline" to="/events">
          Events
        </Link>
        <Link className="hover:underline" to="/gallery">
          Gallery
        </Link>
        <Link className="hover:underline" to="/sponsors">
          Sponsors
        </Link>
      </div>

      {/* Up nav for mobile view */}
      <div
        id="open"
        onClick={openNav}
        className="sm:hidden cursor-pointer block"
      >
        <IoMenu size={40} />
      </div>

      <div
        id="upNav"
        className="absolute z-[100000] left-0 pt-4 bg-black desc w-[100%] duration-500 top-0 translate-y-[-100%] flex flex-col"
      >
        <div className="font-bold text-2xl justify-center items-center flex flex-col gap-4 md:gap-4 text-[#64b5eb]">
          <Link
            className="justify-start items-center flex w-full pl-8 py-2 mt-2 hover:bg-blue-300 hover:text-black transition-colors duration-200 ease-linear"
            to="/"
          >
            <LuGlobe2 className="mr-2"/>
            Home
          </Link>
          <hr />
          <Link
            className="justify-start items-center flex w-full pl-8 py-2 hover:bg-blue-300 hover:text-black transition-colors duration-200 ease-linear"
            to="/events"
          >
            <LuCalendar className="mr-2"/>
            Events
          </Link>
          <hr />
          <Link
            className="justify-start items-center flex w-full pl-8 py-2 hover:bg-blue-300 hover:text-black transition-colors duration-200 ease-linear"
            to="/gallery"
          >
            <LuImage className="mr-2"/>
            Gallery
          </Link>
          <hr />
          <Link
            className="justify-start items-center flex w-full pl-8 py-2 hover:bg-blue-300 hover:text-black transition-colors duration-200 ease-linear"
            to="/sponsors"
          >
            <LuHeartHandshake className="mr-2"/>
            Sponsors
          </Link>
        </div>

        <div
          id="close"
          onClick={closeNav}
          className="my-4 justify-end flex items-start cursor-pointer text-[#64b5eb] mr-8"
        >
          <IoCloseSharp size={30} />
        </div>
      </div>
    </div>
  );
}

export default MyNAvbar;
